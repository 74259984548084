import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import twitter from "../img/socials/twitter.png";
import sqtwitter from "../img/socials/sqtwitter.png";
import facebook from "../img/socials/facebook.png";
import sqfacebook from "../img/socials/sqfacebook.png";
import linkedin from "../img/socials/linkedin.png";
import sqlinkedin from "../img/socials/sqlinkedin.png";
import paypal from "../img/pp.png";
import sqpaypal from "../img/sqpp.png";

import "./RFooter.css";

function RFooter() {
  return (
    <div className="footer-c">
      <div className="footer-about">
        <h3>WriteBing</h3>
        <p>
          We understand that pursuing a higher education degree can be
          challenging, and that's why we offer personalized and reliable support
          to help you succeed.
        </p>
        <ul className="footer-socials">
          <li>
            <a
              href="https://www.instagram.com/WriteBing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                alt="twitter"
                effect="blur"
                src={twitter}
                placeholderSrc={sqtwitter}
                width="50px"
                height="50px"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100076290855952"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                alt="facebook"
                effect="blur"
                src={facebook}
                placeholderSrc={sqfacebook}
                width="50px"
                height="50px"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/writebing/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                alt="linkedin"
                effect="blur"
                src={linkedin}
                placeholderSrc={sqlinkedin}
                width="50px"
                height="50px"
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-r">
        <div>
          <Link to="/about-us">About us</Link>
        </div>
        <div>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </div>
        <div>
          <Link to="/privacy">Fair use policy</Link>
        </div>
        <div>
          <Link to="/contact">Contact us</Link>
        </div>
        {/* <div>
          <Link to="/">Earn with us</Link>
        </div> */}
        <div>
          <Link to="/blog">Blog</Link>
        </div>
      </div>
      <div className="paypal">
        <h2>Payment partner</h2>
        <LazyLoadImage
          alt="paypal"
          effect="blur"
          src={paypal}
          placeholderSrc={sqpaypal}
          width="40px"
          height="40px"
        />
      </div>
      <a href="qodeit.com" className="copyright">
        © 2024 WriteBing. All rights reserved.
      </a>
    </div>
  );
}

export default RFooter;
