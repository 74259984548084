import React, { useState, useEffect } from 'react';
import './RNavbar.css';
import ham from '../img/more.png';
import webLogo from '../img/webLogot.png';
import { Link } from 'react-router-dom';

function RNavbar({ main }) {
  const [isMobile, setIsMobile] = useState(false);

  function handleResize() {
    setIsMobile(window.innerWidth <= 750);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  function myFunction() {
    const header = document.querySelector('.navbar');
    const content = document.querySelector(`.${main}`);
    const sticky = header ? header.offsetTop : null;

    if (header && content) {
      if (window.innerWidth > 750) {
        if (window.pageYOffset > sticky) {
          content.classList.add('martop');
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
          content.classList.remove('martop');
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', myFunction);
    return () => {
      window.removeEventListener('scroll', myFunction);
    };
  }, []);

  function smoothScroll(e) {
    const to = document.querySelector(`.${e}`);
    to.scrollIntoView({ behavior: 'smooth' });
  }

  function handleHamClick() {
    const ul = document.querySelector('.ham-ul');
    ul.classList.toggle('hide');
  }

  return (

    <div>
      
      <div className="navbar">
        <div className="logo">
          <a href="/">
            <img src={webLogo} alt="logo" />
          </a>
        </div>
        <ul id="main-ul" className={isMobile ? 'hide' : ''}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/service">Services</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/how-we-work">How we work!</Link>
          </li>
          <li>
            <Link to="/faq">FAQs!</Link>
          </li>
          <li className="connect-color">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        {isMobile && (
          <div className="ham-c">
            <div className="ham-img" onClick={handleHamClick}>
              <img src={ham} alt="menu" />
            </div>
            <ul className="ham-ul hide">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/how-we-work">How we work!</Link>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://dreamy-yeot-12c832.netlify.app/">
                  FAQs
                </a>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default RNavbar;
