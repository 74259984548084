import { useState } from "react";
import { useLocation } from "react-router-dom";
import RNavbar from "../component/RNavbar";
import RFooter from "../component/RFooter";
import "./ThankYou.css";

export default function ThankYou() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userName = queryParams.get("name") || "User";
    // const userEmail = queryParams.get("email") || "";

    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleFeedbackSubmit = (e) => {
        e.preventDefault();
        alert("Thank you for your feedback!");
        setFeedback("");
    };

    return (
        <div className="thank-you-page">
            <RNavbar main="hww-heading" />

            <div className="thank-you-container">
                <h1>Thank You, {userName}!</h1>
                <p>Your application has been submitted successfully.</p>
                <p>We will get back to you soon.</p>

                <h2>We Value Your Feedback</h2>
                <form onSubmit={handleFeedbackSubmit} className="feedback-form">
                    <textarea
                        placeholder="Share your experience with us..."
                        value={feedback}
                        onChange={handleFeedbackChange}
                        className="feedback-input"
                        required
                    ></textarea>
                    <button type="submit" className="submit-btn">Submit Feedback</button>
                </form>
            </div>

            <RFooter />
        </div>
    );
}
